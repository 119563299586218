import React from 'react'
import { compose, pure } from 'recompose'
import { graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'
import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import SurfacesAcceptantPeuEau from '../../components/methode-raypath/SurfacesAcceptantPeuEau'

const SurfacesAcceptantPeuEauPage = ({ data, t }) => {
  const images = {
    image1: data.image1.childImageSharp,
    image2: data.image2.childImageSharp,
    image3: data.image3.childImageSharp,
    image4: data.image4.childImageSharp
  }

  return (
    <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathSurfacesAcceptantPeuEau">
      <SurfacesAcceptantPeuEau images={images} />
    </SecondaryLayout>
  )
}

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('surfaces-acceptant-peu-d-eau')
)

export default echance(SurfacesAcceptantPeuEauPage)

export const pageQuery = graphql`
  query SurfacesAcceptantPeuEauImageQuery {
    image1: file(relativePath: { eq: "methode/peu-eau-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image2: file(relativePath: { eq: "methode/peu-eau-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image3: file(relativePath: { eq: "methode/peu-eau-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image4: file(relativePath: { eq: "methode/peu-eau-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
  }
`
